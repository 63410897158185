import { jsxs as p, jsx as o } from "react/jsx-runtime";
import { AppProvider as e, CustomizationProvider as m } from "@bushelpowered/bushel-web-offers";
import { CashBidsListPage as a } from "@bushelpowered/bushel-web-offers/pages";
import { u as d } from "../useFrontEndProps-omNav9Sx.js";
function C({
  onViewOffers: r,
  ...t
}) {
  const { onToast: s, toastContainer: i, ...n } = d(t);
  return /* @__PURE__ */ p(e, { ...n, children: [
    /* @__PURE__ */ o(m, { onToast: s, children: /* @__PURE__ */ o(a, { onViewOffers: r }) }),
    i
  ] });
}
export {
  C as CashBidsFrontEnd
};
