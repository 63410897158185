import { jsxs as s, jsx as r } from "react/jsx-runtime";
import { AppProvider as e, CustomizationProvider as p } from "@bushelpowered/bushel-web-offers";
import { OfferListPage as f } from "@bushelpowered/bushel-web-offers/pages";
import { u as m } from "../useFrontEndProps-omNav9Sx.js";
function P(o) {
  const { onToast: t, toastContainer: n, ...i } = m(o);
  return /* @__PURE__ */ s(e, { ...i, children: [
    /* @__PURE__ */ r(p, { onToast: t, children: /* @__PURE__ */ r(f, {}) }),
    n
  ] });
}
export {
  P as OffersFrontEnd
};
