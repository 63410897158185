import { jsx as r, jsxs as i } from "react/jsx-runtime";
import { AppProvider as e, CustomizationProvider as p } from "@bushelpowered/bushel-web-offers";
import { FuturesListPage as m } from "@bushelpowered/bushel-web-offers/pages";
import { u } from "../useFrontEndProps-omNav9Sx.js";
function F(o) {
  const { onToast: t, toastContainer: n, ...s } = u(o);
  return /* @__PURE__ */ r(e, { ...s, children: /* @__PURE__ */ i(p, { onToast: t, children: [
    /* @__PURE__ */ r(m, {}),
    n
  ] }) });
}
export {
  F as FuturesFrontEnd
};
